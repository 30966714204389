














































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getPermissions,
  updatePermissions,
} from '@/serve/permissionsManage/roleManage.ts';
import { Tree } from 'element-ui';
import { TreeData, TreeNode } from 'element-ui/types/tree';
import { getInfo } from '@/serve/index.ts';
@Component({
  name: 'AddPermissionsForm',
})
export default class AddPermissionsForm extends Vue {
  @Watch('filterText')
  change(val: string) {
    (this.$refs.tree as Tree).filter(val);
  }
  public dialogPermissionsForm = false;
  public treeData = [];
  public chooseData: number[] = [];
  public filterText = '';
  public roleId = 0;
  public defaultProps = {
    children: 'children',
    label: 'name',
  };
  public created() {
    //
  }
  //点击编辑用户按钮，接受Role行数据，触发弹窗
  public async addPermissisons(role: any) {
    const res = await getPermissions(role.roleId);
    this.roleId = role.roleId;
    this.chooseData = res;
    this.dialogPermissionsForm = true;
  }
  //进行搜索权限节点
  public filterNode(value: any, data: { name: string | any[] }) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }
  //更新权限
  public async handlerUpdate() {
    const data = {
      menuIds: (this.$refs.tree as Tree).getCheckedKeys().join(','),
      roleId: this.roleId,
    };
    const res = await updatePermissions(data);
    if (res.data) {
      const info = await getInfo();
      //储存用户权限
      sessionStorage.setItem(
        'permissions',
        JSON.stringify(info.data.permissions)
      );
      this.$message({
        message: '更新成功',
        type: 'success',
      });
    } else {
      this.$message({
        message: '更新失败',
        type: 'warning',
      });
    }
    this.dialogPermissionsForm = false;
  }
  //因为element节点点击的反馈不能符合需求，所以写了个小轮子
  //拿到当前被点击的节点,调用getNode，
  public checkChange(row: { id: number }) {
    const node = (this.$refs.tree as Tree).getNode(row.id);
    this.isCheck(node);
  }
  //判断当前是被选中，还是被取消选中
  public isCheck(node: TreeNode<any, TreeData>) {
    if (node.checked) {
      this.parentCheck(node);
    } else {
      this.sonCancleCheck(node);
    }
  }
  //当前被点击节点之上的所有父节点也被选中
  public parentCheck(node: TreeNode<any, TreeData>) {
    if (node.parent) {
      this.$set(node.parent, 'checked', true);
      this.parentCheck(node.parent);
    }
  }
  //取消选中，默认子选项全部取消打勾
  public sonCancleCheck(node: TreeNode<any, TreeData>) {
    node.childNodes.forEach((ele: TreeNode<any, TreeData>) => {
      ele.checked = false;
      //在这里要感谢TS，我差点写成ele.childNodes了，递归子节点Node对象下·
      this.sonCancleCheck(ele);
    });
  }
}
