


























































import { Component, Vue } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { editRole } from '@/serve/permissionsManage/roleManage.ts';
import { ElTimePicker } from 'element-ui/types/time-picker';
@Component({
  name: 'EditForm',
})
export default class EditForm extends Vue {
  public dialogeidtForm = false;
  public form = {
    $index: 0,
    roleName: '',
    roleCode: '',
    roleDesc: '',
    createTime: '',
  };
  public rules = {
    roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
    roleCode: [{ required: true, message: '请输入角色标识', trigger: 'blur' }],
  };
  public pickerOptions = {
    shortcuts: [
      {
        text: '今天',
        onClick(picker: ElTimePicker) {
          picker.$emit('pick', new Date());
        },
      },
      {
        text: '昨天',
        onClick(picker: ElTimePicker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit('pick', date);
        },
      },
      {
        text: '一周前',
        onClick(picker: ElTimePicker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', date);
        },
      },
    ],
  };
  public formLabelWidth = '120px';
  public created() {
    //
  }
  //点击编辑用户按钮，接受Role行数据，触发弹窗
  public editRole(index: number, role: any) {
    this.form = role;
    this.$set(this.form, '$index', index);
    this.dialogeidtForm = true;
  }
  //编辑保存接口
  public async handlerEdit() {
    (this.$refs.form as ElForm).validate(async (valid) => {
      if (valid) {
        const res = await editRole(this.form);
        res.data &&
          this.$message({
            message: '修改成功',
            type: 'success',
          });
        this.dialogeidtForm = false;
      }
    });
  }
  //清空表单验证和数据
  public closeDialog() {
    (this.$refs.form as ElForm).resetFields();
  }
}
