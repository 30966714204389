import request from '../../utils/request'

interface Page {
  current: string | number;
  size: string | number;
}
//获取角色表
export function getRoleData(data: Page): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/role/page',
    type: 'params',
    data: data
  })
}

interface Form<T> {
  $index: number;
  createTime: T;
  delFlag?: T;
  roleCode: T;
  roleDesc: T;
  roleId?: T;
  roleName: T;
  updateTime?: T;
}
//编辑角色表
export function editRole(form: Form<string>): Promise<any> {
  return request({
    method: 'PUT',
    url: 'admin/role',
    data: form
  })
}
//根据ID删除某角色  
export function delRole(id: number) {
  return request({
    url: 'admin/role/' + id,
    method: 'delete'
  })
}
//获取权限列表,如果有传递ID值说明获取的是单个角色已选的权限，反正获取所有权限
export function getPermissions(id?: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/menu/tree/' + `${id ? id : ''}`
  })
}
interface TreeData {
  menuIds: string;
  roleId: number;
}
//获取权限列表,如果有传递ID值说明获取的是单个角色已选的权限，反正获取所有权限
export function updatePermissions(data: TreeData): Promise<any> {
  return request({
    method: 'PUT',
    url: 'admin/role/menu',
    data
  })
}

