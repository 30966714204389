














































































































import { Component, Vue } from 'vue-property-decorator';
import {
  getRoleData,
  delRole,
  getPermissions,
} from '@/serve/permissionsManage/roleManage.ts';
import EditForm from '@/views/permissionsManage/roleManage/editForm.vue';
import AddPermissionsForm from '@/views/permissionsManage/roleManage/addPermissionsForm.vue';
import { Row } from 'element-ui';
@Component({
  name: 'RoleManage',
  components: { EditForm, AddPermissionsForm },
})
export default class RoleManage extends Vue {
  public roleData = [];
  public page = {
    current: 1,
    size: 20,
  };
  public total = 0;
  public created() {
    this.getRoleData();
    this.getAllPermissions();
  }
  //获取角色表数据
  public async getRoleData(): Promise<void> {
    const res = await getRoleData(this.page);
    this.roleData = res.data.records;
    this.total = res.data.total;
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: number): void {
    this.page.current = val;
    this.getRoleData();
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: number): void {
    this.page.size = val;
    this.getRoleData();
  }
  //触发编辑按钮
  public editRow(index: number, row: Row) {
    (this.$refs.editForm as EditForm).editRole(index, row);
  }
  //点击删除按钮
  public deleteRole(row: { roleId: number; roleName: string }) {
    //roleId
    (this as any).$messageBox
      .confirm(` 是否继续删除该角色:(${row.roleName})?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const res = await delRole(row.roleId);
        this.getRoleData();
        res.data
          ? this.$message({
              message: '删除成功',
              type: 'success',
            })
          : this.$message({
              message: '删除失败',
              type: 'warning',
            });
      });
  }
  //获取所有权限,赋值给权限树tree
  public async getAllPermissions() {
    const res = await getPermissions();
    this.$nextTick(() => {
      (this.$refs.AddPermissionsForm as AddPermissionsForm).treeData = res.data;
    });
  }
  //点击更新权限按钮
  public addPermissions(row: Row) {
    (this.$refs.AddPermissionsForm as AddPermissionsForm).addPermissisons(row);
  }
}
