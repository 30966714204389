var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roleManage"},[_c('main',[_c('p',{ref:"main",staticClass:"main_p"},[_c('span',[_vm._v("角色列表")])]),_c('div',{staticClass:"main_main"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"header-cell-style":{
          fontSize: '14px',
          background: '#fafafa',
          color: 'black',
        },"highlight-current-row":"","border":"","stripe":"","data":_vm.roleData}},[_c('el-table-column',{attrs:{"align":"center","label":"序号","width":"70","type":"index"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roleName","width":"150","label":"角色名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roleCode","label":"角色标识","width":"200"}}),_c('el-table-column',{attrs:{"align":"center","label":"角色描述","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{staticStyle:{"margin-right":"3px"}},[_vm._v(_vm._s(scope.row.roleDesc))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"createTime","label":"创建时间","width":"170"}}),(
            _vm.permissions['sys_role_edit'] ||
            _vm.permissions['sys_role_del'] ||
            _vm.permissions['sys_role_perm']
          )?_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.permissions['sys_role_edit'])?_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-edit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.editRow(scope.$index, scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.permissions['sys_role_del'])?_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRole(scope.row)}}},[_vm._v(" 移除 ")]):_vm._e(),(_vm.permissions['sys_role_perm'])?_c('el-button',{attrs:{"size":"mini","icon":"el-icon-plus","type":"success"},on:{"click":function($event){return _vm.addPermissions(scope.row)}}},[_vm._v("权限")]):_vm._e()]}}],null,false,2405979684)}):_vm._e()],1)],1)]),_c('footer',[_c('div',{staticClass:"block"},[_c('el-pagination',{attrs:{"current-page":_vm.page.current,"page-sizes":[10, 20, 30, 40, 50],"page-size":20,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total,"background":""},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]),_c('EditForm',{ref:"editForm"}),_c('AddPermissionsForm',{ref:"AddPermissionsForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }